<template>
  <BaseList
    :list-query="list"
    :completions-query="completions"
    route="uri"
    locale-section="pages.uris"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "URISList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query uris {
          list: uris {
            id
            name: uri
            uri
          }
        }
      `,
      completions: gql`
        query findByURI($search: String!) {
          completions: findByURI(uri: $search) {
            name: uri
            id
          }
        }
      `
    };
  }
};
</script>
